<template>
  <div>
    <PageTitle :title="''" :subtitle="'Frequently Asked Questions'"></PageTitle>
    <BodyBox class="content-box">
      <div v-for="(section, scetionIndex) in sections" :key="scetionIndex" class="section-box" :id="section.ID">
        <div class="l-t-a vl-t-s b-t-w title-t-c section-title line-height: 1.2em;" :class="section?.faqs ? '': 'text-4xl'">{{section.section}}</div>
        <ExpandableRow v-for="(faq) in section.faqs" :key="faq.index" :item="faq" :id="faq.ID || null" v-on:clicked="clickedItem(faq.index)" :expandedItem="expandedItem" class="faq"></ExpandableRow>
      </div>
    </BodyBox>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import PageTitle from '@/components/PageTitle'
import BodyBox from '@/components/BodyBox'
import ExpandableRow from '@/components/ExpandableRow'
export default {
  components:{
    PageTitle,
    BodyBox,
    ExpandableRow
  },
  data() {
    return {
      sections:[
        {
          section: "Troopers FAQs",
          ID:"troopers-faqs",
        },
        {
          section: "General",
          ID:"general",
          faqs: [
            {
              title: 'What is Troopers?',
              contnet: `Troopers is an app-based part-time/freelance job search, matching & management platform that connects talents seeking for part-time jobs with our thoroughly-vetted employers. With the aim of making part-time jobs more accessible, our app enables users to search and apply for part-time jobs easily, work on a flexible schedule and be assured that they are paid on time. Troopers helps companies to fill gaps in their workforce without the financial burden of permanent hires.`
            },
            {
              title: 'How can I download the Troopers app?',
              contnet: 'You may download it from the App Store or Google Play Store.'
            },
            {
              title: "I'm looking for a full-time job. Can I use this app?",
              contnet: `Troopers only features part-time listings at this time and may be not be a suitable platform for you.`
            }
          ]
        },
        {
          section: "Account Settings",
          ID:"account-settings",
          faqs: [
            {
              title: 'How do I sign up for a Troopers account?',
              contnet: `Sign up for a Troopers account by filling up basic account information like your name, phone number and password. Create a profile and include as much information as you can to get noticed by employers when you apply for a job via the Troopers app.`
            },
            {
              title: 'I have registered but did not receive a verification code.',
              contnet: `Your account registration will be verified via a verification code. If you did not receive a verification code, kindly check that your phone number has been entered correctly during registration. Alternately, you may click on <strong>'Tap to resend the code'</strong> in 30 seconds after the previous request and a new verification code will be sent to you. If the problem persists, do contact us for assistance.`
            },
            {
              title: 'Can I have multiple bank accounts in my Troopers account?',
              contnet: `Troopers does not support this feature and only one bank account is allowed per user. Please ensure that your bank account details provided are registered to your NRIC name to get paid on time.`
            },
            {
              title: 'Can I sign up for 2 or more user accounts?',
              contnet: `No, each individual can only sign up for <strong>ONE (1)</strong> Troopers account. This assures information accuracy, account security and prevents possible disputes from identity complications.`
            },
            {
              title: 'How do I change/reset my password?',
              contnet: `Sign in to your account and follow these steps:
              <ol>
                <li>Click on <strong>Profile.</strong></li>
                <li>Select <strong>Change password</strong>.</li>
                <li>Enter your current and new password, click on the eye icon to verify again before you confirm your new password.</li>
              </ol>`
            },
            {
              title: 'I forgot my password. How do I recover my login?',
              contnet: `On the <strong>Log In page</strong>, please perform the following steps:
              <ol>
                <li>Click on <strong>Forgot your password?</strong></li>
                <li>A password reset link will be sent to you via SMS to your registered phone number.</li>
                <li>Click on the link provided and reset your password. </li>
                <li>Verify your new password by clicking on the eye icon and confirm your new password.</li>
              </ol>`
            },
            {
              title: 'How do I delete my account?',
              contnet: `While we're sad to see you go, you may do so by contacting the Troopers support team at support@troopers.com.my to get your account removed.`
            },
            {
              title: 'How do I change my phone number?',
              contnet: `You may contact the Troopers support team at support@troopers.com.my to change your phone number.`
            }
          ]
        },
        {
          section: "Job-related Matters",
          ID:"job-related-matters",
          faqs:[
            {
              title: 'How do I apply for a job?',
              contnet: `
              <ol>
                <li>Browse the job listings on the <strong>Discover</strong> page.</li>
                <li>Log in to your account and carefully scan through the Job Description, Working Information and Requirements stated for a specific job.</li>
                <li>Ensure that you've met all the requirements for the job.</li>
                <li>Click on <strong>'Apply Now'</strong>, pick your available dates and your application has been successfully submitted.</li>
              </ol>`
            },
            {
              title: 'How long does it take for a employer to respond to my application?',
              contnet: `It usually takes up to <strong>48 hours</strong> for an employer to respond to your application. If the employer fails to respond within 48 hours, your job application status will change to Keep In View (KIV), this means that you're allowed to apply for other jobs with the same dates you've picked for your current job application.`
            },
            {
              title: 'Why was my application rejected?',
              contnet: `While we're unable to provide a definite answer because Troopers only facilitates the employer's/hiring managers' talent selection process. However, here may be a few possible reasons why:
              <ol>
                <li>You did not meet the requirements stated by the employer.</li>
                <li>You tried to apply for a job listing that is no longer accepting applications.</li>
                <li>There were incomplete details in your profile.</li>
                <li>The employer decided to hire other candidates for this particular job.</li>
              </ol>
                However, don't be discouraged. Explore more listings on the Discover page and apply.`
            },
            {
              title: `I've accidentally cancelled a job I wanted to work for. Can I still undo the action?`,
              contnet: `Don't panic, you may undo the action by contacting the Troopers support team with these steps:
              <ol>
                <li>Go to <strong>My Profile</strong>.</li>
                <li>Select <strong>Contact us.</strong></li>
                <li>Fill out the contact form and our support team will revert the action for you. Give details so that our team understands the situation.</li>
                <li>Your job confirmation will be reflected in 1-3 working days.</li>
              </ol>`
            },
            {
              title: `I've confirmed a job offer but realised that I can't make it for the job. Can I still cancel it?`,
              contnet: `You may cancel the job by following these steps:
              <ol>
                <li>Go to <strong>My Jobs</strong>.</li>
                <li>Look for the job post and you will find your supervisor's information under <strong>Details > Supervisor Details</strong>.</li>
                <li>Inform your supervisor at least 7 days ahead of the scheduled job and you're done. </li>
                <li>Your job cancellation will be reflected in 1-3 working days.</li>
              </ol>`
            },
            {
              title: `I forgot to check in! However, I did turn up for work on time. What should I do now?`,
              contnet: `Don't worry. To fix this, sign in to your account and follow these steps:
              <ol>
                <li>Click on <strong>My Jobs > Details</strong>.</li>
                <li>Check for your supervisor's information under <strong>Supervisor Details</strong>.</li>
                <li>Contact your supervisor to check in manually.</li>
              </ol>`
            }
          ]
        },
        {
          section: "Shift-related Matters",
          ID:"shift-related-matters",
          faqs:[
            {
              title: `Why was my shift auto reported?`,
              contnet: `Your shift was reported by the TROOPERS system because you did not check-in to your shift on time. This rule was communicated to you as part of your training and multiple reminder notifications would have been sent to you before the shift started. If you think this was a mistake, please contact Support.`
            },
          ]
        },
        {
          section: "Account Security",
          ID:"account-security",
          faqs:[
            {
              title: `I can't seem to receive a verification code even though I've requested multiple times. What should I do?`,
              contnet: `Please ensure that your registered phone number is correct. Each verification code is only valid for 30 seconds. You may only request for a new one after 30 seconds if you did not receive a verification code during your previous attempt. If the problem persists, do contact us for assistance.`
            },
            {
              title: `Is this app secure?`,
              contnet: `Yes, the Troopers app is equipped with various security features to safeguard our users.
              <ol>
                <li>Your Troopers account is linked to your phone number. Any account changes within the app will automatically prompt a notification alert to your email/ phone.</li>
                <li>Only 1 login session is allowed per Troopers app access for each account. If another user login is detected from another device, the subsequent login attempt will not be successful.</li>
                <li>Sensitive data such as password or transaction information will not be transmitted on public network through secured protocols SSL, TLS and HTTPS.</li>
                <li>Confidential account information is not stored on the mobile device.</li>
              </ol>`
            },
            {
              title: `Can I log in to my account with multiple devices concurrently?`,
              contnet: `No, you can only log in to your account using one device at a time. Login attempts from other devices thereafter will not be successful. This is one of our security measures to keep your data protected.`
            },
          ]
        },
        {
          section: "Getting Paid",
          ID:"getting-paid",
          faqs:[
            {
              title: `Why are the available earnings different from my total earnings?`,
              contnet: `<strong>Total Earnings'</strong> reflects the total amount of earnings for your entire job duration. Upon job completion, the earnings will be credited into your account under <strong>'Total Earnings'</strong>. Your earnings will then be moved to <strong>'Available Earnings'</strong> based on the payment terms stated in the job description. <strong>'Available Earnings'</strong> shows the amount that you are able to withdraw.`
            },
            {
              title: `How do I withdraw earnings to my bank account from my available earnings?`,
              contnet: `You may withdraw from your <strong>'Available Earnings'</strong> to your bank account using these steps:
              <ol>
                <li>Go to <strong>My Profile</strong>.</li>
                <li>Select <strong>Withdraw Earnings</strong>.</li>
                <li>Complete the required bank account information and enter your withdrawal amount. Ensure that your withdrawal amount does not exceed your <strong>'Available Earnings'</strong>.</li>
              </ol>`
            },
          ]
        },
        {
          section: "Withdrawal",
          ID: "withdrawal",
          faqs:[
            {
              title: `My withdrawals are approved but I have not received my earnings.`,
              contnet: `Hooray! Your withdrawals have been approved and your earnings should be on your way in no time! Kindly refer to the payment cycle below to know when you will be receiving your earnings:
              <br><br>
              <table style="width:100%; border-collapse: collapse; text-align: center; border: 1px lightgray solid;"> 
                <tr style="border: 1px lightgray solid; color: white; background-color: #0D1A48;">
                  <th style="border: 1px lightgray solid; padding: 0.6em">Withdrawal Request Date On</th>
                  <th style="border: 1px lightgray solid; padding: 0.6em">Withdrawal Request time</th>
                  <th style="border: 1px lightgray solid; padding: 0.6em">Payment received by User on</th>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Monday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Thursday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Tuesday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Thursday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Wednesday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Monday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Thursday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Monday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Friday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Saturday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Sunday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
              </table>`
            },
            {
              title: `I made a withdrawal but have not received my earnings. When will I receive it?`,
              contnet: `Don't worry, your earnings are currently being processed by the bank. You should be able to receive your earnings in a jiffy! Kindly refer to the payment cycle table below for further details.
              <br><br>
              <table style="width:100%; border-collapse: collapse; text-align: center; border: 1px lightgray solid;"> 
                <tr style="border: 1px lightgray solid; color: white; background-color: #0D1A48;">
                  <th style="border: 1px lightgray solid; padding: 0.6em">Withdrawal Request Date On</th>
                  <th style="border: 1px lightgray solid; padding: 0.6em">Withdrawal Request time</th>
                  <th style="border: 1px lightgray solid; padding: 0.6em">Payment received by User on</th>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Monday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Thursday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Tuesday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Thursday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Wednesday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Monday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Thursday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Monday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Friday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Saturday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Sunday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
              </table>`
            },
            {
              title: `My withdrawal request has been rejected. What should I do?`,
              contnet: `Uh oh! Your payment has been rejected due to various reasons. Kindly go through your profile details provided below for any minor errors or if you have missed out on any numbers. 
              <br><br>Alternatively, your earnings may be rejected due to a salary adjustment. Do reach out to us via <strong>Contact Us</strong> on your Troopers app for further assistance! :
              <ol>
                <li>Full Name</li>
                <li>NRIC</li>
                <li>Bank Account Name</li>
                <li>Bank Account Number</li>
                <li>Bank Account Holder Name</li>
              </ol>`
            },
            {
              title: `My payment is delayed due to the public holiday. When will I receive my earnings?`,
              contnet: `Apologies for the delay! Due to the public holiday, the office will be closed. You may refer to the announcement made on your Troopers app regarding your payout.
              Alternatively, you may reach out to our <strong>User Support</strong> team via your <strong>Contact Us</strong> feature on your Troopers app and they will assist you accordingly!`
            },
          ]
        },
        {
          section: "Payment Issues and Disputes",
          ID:"payment-issues-and-disputes",
          faqs:[
            {
              title: `I just got reported by my supervisor. How do I file an appeal?`,
              contnet: `To file an appeal on your supervisor's report, log in to your account and follow these steps:
              <ol>
                <li>Go to <strong>My Profile</strong>.</li>
                <li>Select <strong>Contact us</strong>.</li>
                <li>Fill out the contact form and our support team will review the appeal. Give details to support your appeal so that our team understands the situation. You will be contacted within 1-3 working days.</li>
              </ol>`
            },
            {
              title: `I ran into an issue with work/ supervisor/ colleagues. How do I report this?`,
              contnet: `At Troopers, we prioritise the safety and comfort of our part-timers. To file a report on your issue, log in to your account and follow these steps:
              <ol>
                <li>Go to <strong>My Profile</strong>.</li>
                <li>Select <strong>Contact us</strong>.</li>
                <li>Fill out the contact form and our support team will look into the issue. Explain the issue in detail so that our team understands the problem. You will be contacted within 1-3 working days.</li>
              </ol>`
            },
            {
              title: `I've completed my job. How come the earnings are not reflected in my user account?`,
              contnet: `Please allow 2 to 3 working days for your earnings to be reflected in your user account upon a job completion. If the amount is not reflected after 3 working days, please contact our support team by going to <strong>My Profile > Contact us</strong>.`
            },
            {
              title: `The earnings received/ withdrawal amount is not right. What should I do?`,
              contnet: `
              <ol>
                <li>Go to <strong>My Profile</strong>.</li>
                <li>Select <strong>Contact us</strong>.</li>
                <li>File your dispute in the contact form and our support team will get back to you within 1-3 working days.</li>
              </ol>`
            },
            {
              ID: "payment-cycle",
              title: `I've submitted an earnings withdrawal request. How come the withdrawal amount is not reflected in my bank account?`,
              contnet: `Please allow 3 to 5 working days for the amount to be reflected in your bank account upon an earnings withdrawal request. You will be notified via email and push notifications when your request has been approved and is currently being processed by the bank.
              <br>If the amount is not reflected after 5 working days, please contact our user support team by going to <strong>My Profile > Contact us.</strong>
              <br><br>The approval timeline for your withdrawal request is subject to our payment cycle table below:
              <br>
              <table style="width:100%; border-collapse: collapse; text-align: center; border: 1px lightgray solid;"> 
                <tr style="border: 1px lightgray solid; color: white; background-color: #0D1A48;">
                  <th style="border: 1px lightgray solid; padding: 0.6em">Withdrawal Request Date On</th>
                  <th style="border: 1px lightgray solid; padding: 0.6em">Withdrawal Request time</th>
                  <th style="border: 1px lightgray solid; padding: 0.6em">Payment received by User on</th>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Monday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Thursday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Tuesday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Thursday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Wednesday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Monday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Thursday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Monday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Friday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Saturday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
                <tr style="border: 1px #fff4eb solid; color: black;">
                  <td style="border: 1px #fff4eb solid; color: white; background-color: orange; padding: 0.2em;"">Sunday</td>
                  <td style="border: 1px orange solid;">12 AM - 11.59 PM</td>
                  <td style="border: 1px orange solid;">Tuesday</td>
                </tr>
              </table>`
            },
          ]
        },
        {
          section: "Early Conversion",
          ID: 'early-conversion',
          faqs: [
            {
              title: 'What is Early Conversion?',
              contnet: `Early Conversion is a feature where we equip part-timers with the ability to take full control of how and when they get paid.`
            },
            {
              title: 'What is Early Conversion rate?',
              contnet: `Early Conversion rate is a rate that will be charged upon your conversion request.`
            },
            {
              title: 'Do I need to register for Early Conversion?',
              contnet: `No, you don't need to register for Early Conversion however, this feature is made available on a job to job basis. `
            },
            {
              title: 'How to use Early Conversion?',
              contnet: `Go to your profile on the Troopers app > Withdraw Earnings > and follow the instructions provided.`
            },
            {
              title: 'How to request to convert Job Earnings?',
              contnet: `You can request to convert your job earnings in your <strong>"My Job's"</strong> tab. `
            },
            {
              title: 'Can I select which Job Earnings I want to do Early Conversion on?',
              contnet: `Yes, you are allowed to choose from whichever job earnings that you would like to perform Early Conversion.`
            },
            {
              title: 'Are there any charges to use Early Conversion?',
              contnet: `Yes, you will be charged based on when you decide to execute the conversion.  The longer you wait, the lower the rates`
            },
            {
              title: 'How do I know when my Total Earnings have been successfully converted into Available Earnings?',
              contnet: `You will receive a notification on the Troopers App upon successful conversion.`
            },
            {
              title: 'Can I directly withdraw my converted earnings?',
              contnet: `Yes, you can withdraw after your request is approved and the amount has been converted into your Available Earnings.`
            },
            {
              title: "Can I use Early Conversion when I'm traveling overseas?",
              contnet: `Yes, you can convert wherever and whenever. `
            },
            {
              title: 'Must I use the Early Conversion feature? ',
              contnet: `No.  The early conversion feature is an optional feature that allows users to dictate and take full control of when they get paid. `
            },
            {
              title: 'How long will it take for my Total Earnings to be converted upon successful Early Conversion request? ',
              contnet: `Instantly! `
            },
          ]
        },
        {
          section: "Campaigns FAQs",
          ID: 'campaigns-faqs',
        },
        {
          section: `Maybank Download Troopers App Campaign`,
          ID: `maybank-campaign-download`,
          faqs: [
            {
              title: `What is the Troopers App?`,
              contnet: `The Troopers App is a platform that connects users with part-time job opportunities, allowing them to earn extra income.`
            },
            {
              title: `What is the purpose of this campaign?`,
              contnet: `The campaign aims to promote the Troopers App to Maybank users by offering a cash reward for new sign-ups who complete their first job.`
            },
            {
              title: `What is the promotional offer for new Troopers App users?`,
              contnet: `New users who register for a Troopers Account using the referral code "MBB10" and complete their first job will receive an additional RM10 in their Total Earnings.`
            },
            {
              title: `When is this promotion valid?`,
              contnet: `The promotion runs from 31st July 2024 to 31st October 2024.`
            },
            {
              title: `How do I sign up for the Troopers App?`,
              contnet: `Download the Troopers App. Sign up for an account, and use the referral code "MBB10" during registration to be qualified for the promotional offer.`
            },
            {
              title: `Where can I find more information about the Troopers App?`,
              contnet: `More information can be found by clicking on the push notification via the MAE app or by searching for "Maybank Troopers" and you will find a promo page.`
            },
            {
              title: `What should I do if I face issues during registration or job completion?`,
              contnet: `If you encounter any issues, please contact the Troopers User Support team via the Contact Us feature in the TROOPERS app for assistance.`
            },
            {
              title: `What happens after I complete my first job on the Troopers App?`,
              contnet: `After completing your first job, the RM10 reward will be credited to your Total Earnings, and you will receive a notification confirming this. The cash reward is limited and is on a first come first served basis.`
            },
            {
              title: `Is there a limit to the number of cash rewards given out?`,
              contnet: `Yes, the campaign is limited to the first 1000 redemptions during the campaign period. Terms and conditions apply.`
            },
          ],
        },
        {
          section: `Switch Withdrawal Account to Maybank Campaign`,
          ID: `maybank-campaign-switch`,
          faqs: [
            {
              title: `What is the promotional offer for switching my withdrawal account to Maybank?`,
              contnet: `Existing users who switch their withdrawal account to Maybank will receive an additional RM10 in their Maybank account.`
            },
            {
              title: `Who is eligible for this campaign?`,
              contnet: `Existing users who have requested to change their bank account to Maybank between 31st July to 31st October 2024.
              Existing users who have withdrawn funds to a Non-Maybank account at least once before 31st July and within the last 2 years.`
            },
            {
              title: `How do I switch my withdrawal account to Maybank?`,
              contnet: `Navigate to the Troopers App, go to the Contact Us section, and request to change your withdrawal bank account to Maybank.`
            },
            {
              title: `What information do I need to provide to switch my account?`,
              contnet: `You will need to provide your Maybank account details to complete the switch.`
            },
            {
              title: `Can I use a third party's Maybank account to receive the RM10 reward?`,
              contnet: `No, the Maybank account used for the withdrawal must belong to the Troopers user. The account holder's name must match the name on the Troopers account to be eligible for the RM10 reward.`
            },
            {
              title: `What if I don't have a Maybank account?`,
              contnet: `Users who do not have an account with Maybank, should open a Maybank account via the MAE App or visit the nearest Maybank branch to be eligible. Alternatively, you may reach out to our User Support team via the Contact Us feature for further assistance.`
            },
            {
              title: `Can I participate in this promotion if I already have a Maybank account for withdrawals?`,
              contnet: `No, this promotion is specifically for users who switch their withdrawal account from a non-Maybank account to a Maybank account.`
            },
            {
              title: `How and when will I receive the RM10 reward?`,
              contnet: `Once you have successfully switched your withdrawal account to Maybank and completed at least one withdrawal from the Troopers App into your Maybank account, the RM10 reward will be credited within 30 working days after the campaign period.`
            },
            {
              title: `Is there a limit to the number of cash rewards given out?`,
              contnet: `Yes, the campaign is limited to the first 1000 redemptions during the campaign period.`
            },
            {
              title: `Is there a deadline for this promotional offer?`,
              contnet: `Yes, the promotion runs until the 31st of October 2024.`
            },
            {
              title: `Where can I find more information about this campaign?`,
              contnet: `More information can be found on the Troopers' Instagram page and the TROOPERS App.`
            },
            {
              title: `Will I still receive the reward if my account switch is not successful?`,
              contnet: `The reward will only be credited once the account switch is successfully processed to a Maybank account. Terms and conditions apply.`
            },
            {
              title: `Can I redeem the cash reward more than once?`,
              contnet: `No, the cash reward can be redeemed only once per eligible customer.`
            },
            {
              title: `What should I do if I face issues during the account switch process?`,
              contnet: `If you encounter any issues, please contact the Troopers User Support team via the Contact Us feature in the TROOPERS app for assistance.`
            },
          ],
        },
      ],
      expandedItem: -1,
      fetchedCampaignData: null
    }
  },
  methods:{
    ...mapActions({
      getSysConfig: 'getSysConfig',
    }),
    clickedItem(index){
      if(index == this.expandedItem){
        this.expandedItem = -1
      }
      else{
        this.expandedItem = index
      }
    }
  },
  mounted() {
    let newIndex = 1
    for (let i = 0; i < this.sections.length; i++) {
      const section = this.sections[i];
     
     if (section?.faqs?.length) {
      for (let j = 0; j < section.faqs.length; j++) {
        section.faqs[j].index = newIndex;
        newIndex++
      };
     }
    }
    if(this.$route?.hash!=""){
      let elmnt = document.getElementById(this.$route?.hash.substring(1));
      if(elmnt){
        for (const section of this.sections) {
            const faq = section?.faqs?.find((faq) => faq.ID == this.$route.hash.substring(1))
            if (faq){
              this.expandedItem = faq.index
            }
        }
        setTimeout(()=>{
          elmnt.scrollIntoView({
          behavior: 'smooth'
        })}, 100)
      }
    }
  },
}
</script>

<style scoped>
.content-box{
  width: 100%;
  border-radius: .5em;
  position: relative;
  z-index: 1;
  max-width: 50em;
}
.section-box{
  margin-bottom: 1em;
}
.section-title{
  line-height: 1.2em;
  margin-bottom: .3em;
}
.faq{
  margin-bottom: .5em;
}
ol{
  margin-left: .1em;
}

</style>